import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme";
import { navigate } from 'gatsby-link';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { encode } from '../utils/util';



const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const Top = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breaks.down('lg')} {
    width: 80%;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    flex-direction: column-reverse;
  }
`

const Title = styled.h1`
  font-weight: 300;
  font-size: 7rem;
  color: ${props => props.theme.colors.primary};
  width: 450px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    margin: 5rem auto;
    font-size: 5rem;
    text-align: justify;
  }
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 220px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 80px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    margin: 0 auto;
    font-size: 2.5rem;
  }
`

const MainSection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-left: 80px;
  position: relative;

  .bottom-form {
    margin-top: 15px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`

const Form = styled.form`
  width: 60%;
  margin: 0 auto;
  color: ${props => props.theme.colors.body};

  & * {
  color: ${props => props.theme.colors.body};

  }

  .MuiFormLabel-root {
  color: ${props => props.theme.colors.body};
  }

  .MuiInputBase-input {
    border-bottom: 2px solid ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.primary};
  }

  /* .MuiInput-underline{
    border-bottom:  2px solid ${props => props.theme.colors.body};
  } */

  .MuiInputBase-multiline.MuiInput-underline:before {
    bottom: 7px;
  }

  .MuiInputBase-multiline.Mui-focused:after {
    bottom: 7px;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 80%;
  }

`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 96%;
  border: none;
  padding: 15px 0;
  margin: 0 auto;
  margin-top: 80px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;
  z-index: 1;

  @media ${props => props.theme.breaks.down('md')} {
    position: relative;
    padding-left: 0;
    bottom: 0;
    margin-top: 10px;
  }

`


const ServiceFormPage = ({ data }) => {

  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    
      <Layout color={theme.colors.body} pageTitle="Service form">
      <Container>
        
        <MainSection className="point-sec">
          <Top>
            <Title>Wait, what <br /> can you do?</Title>
            <TitleCond>If you didn’t see what you were looking
                for, why not ask?</TitleCond>
          </Top>
          <Form
            name="service-form"
            method="post"
            action="/submitted"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="service-form" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>

            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>

                <TextField fullWidth name="name" label="Your name" placeholder="Your name" color="secondary" value={state.name} onChange={handleChange} required></TextField>

              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="company" label="Your company" placeholder="Your company" color="secondary" value={state.company} onChange={handleChange} required></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="email" label="Your email" placeholder="Your email" type="email" color="secondary" value={state.email} onChange={handleChange} required></TextField>
                
              </Grid>
            </Grid>

            <Grid className="bottom-form" container spacing={5}>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="find" label="How did you find out about us?" placeholder="How did you find out about us?" value={state.find} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="budget" label="Budget for the project?" placeholder="Budget for the project?" value={state.budget} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="time" label="Time frame for the project?" placeholder="Time frame for the project?" type="text" value={state.time} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <TextField multiline rows={4} fullWidth name="about" label="Tell us about the project" value={state.about} onChange={handleChange} placeholder="Tell us about the project" color="secondary"></TextField>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Button>Submit</Button>
            </Grid>
          </Form>

          <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
          
        </MainSection>
        
      </Container>
      </Layout>
    
)
}

export default ServiceFormPage;